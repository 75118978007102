<template>
  <div id="smartai_operation_page" style="margin: 20px" v-cloak>
    <!--基础组件的添加 start--->
    <el-dialog
      title="设置评分组件"
      :visible.sync="dialogBaseVisible"
      width="1200px"
      top="3vh"
    >
      <div
        style="
          padding: 22px 30px 0 10px;
          min-height: 500px;
          overflow-y: auto;
          overflow-x: hidden;
          border: 1px solid #ccc;
        "
      >
        <el-row
          type="flex"
          class="row-bg"
          justify="space-between"
          v-for="(a_item, a_index) in answerData"
          :key="a_index"
        >
          <el-col :span="6"
            ><div class="grid-content bg-purple-left">
              <span>组件名称 ：{{ a_item.basic_name }}</span>
            </div></el-col
          >
          <el-col :span="6"><div class="grid-content"></div></el-col>
          <el-col :span="6"
            ><div class="grid-content bg-purple-right">
              <el-select
                v-if="answerData.length > 1"
                size="mini"
                v-model="a_item.clac_type"
                placeholder="请选择组件计算方式"
              >
                <el-option label="加分" :value="0">加</el-option>
                <el-option label="减分" :value="1">减</el-option>
              </el-select>
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-minus"
                circle
                @click="delOperationByBasic(a_index, a_item.relation_id)"
              ></el-button></div
          ></el-col>
        </el-row>
        <div>
          <el-select
            style="width: 240px; margin-top: 10px"
            @change="changeChoseBasic"
            v-model="current_chose_basic_name"
          >
            <el-option
              v-for="(basic_item, basic_index) in basic_component_list"
              :key="basic_index"
              :label="basic_item.name + '(' + basic_item.sign + ')'"
              :value="basic_item.id"
              :disabled="selectedKeyValArr.includes(basic_item.id)"
            ></el-option>
          </el-select>
          <el-button
            icon="el-icon-plus"
            circle
            @click="addBasicByBase"
          ></el-button>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogBaseVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveOperationByBasic"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--基础组件的添加 end--->

    <!--运营组件的添加 start--->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      width="800px"
      top="3vh"
    >
      <el-form label-width="100px" :model="operation_form" size="small">
        <el-row :gutter="20" type="flex" justify="space-between">
          <el-col :span="12">
            <el-form-item label="运营组件名称" prop="operation_name">
              <el-input v-model="operation_form.operation_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运营组件标识" prop="operation_sign">
              <el-input
                v-model="operation_form.operation_sign"
                placeholder="用于前端获取数据的标识"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="space-between">
          <el-col :span="12">
            <el-form-item label="学校" prop="operation_school_id">
              <el-select
                v-model="operation_form.operation_school_id"
                placeholder="请选择运营组件所属学校"
                clearable
              >
                <el-option
                  v-for="(s_item, s_index) in school_list"
                  :key="s_index"
                  :label="s_item.school_name"
                  :value="s_item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运营组件占比" prop="operation_proportion">
              <el-input
                max="1"
                v-model="operation_form.operation_proportion"
                @input="numberChange(arguments[0], 100)"
                @change="numberChange(arguments[0], 100)"
              >
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="选择分类">
            <el-cascader v-model="operation_form.parent_arr" :options="cateTableData" :props="optionProps" style="width:100%;" clearable></el-cascader>
          </el-form-item> -->
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="space-between">
          <el-col :span="12">
            <el-form-item label="运营组件描述" prop="operation_desc">
              <el-input v-model="operation_form.operation_desc"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="组件状态" prop="status">
              <el-switch
                style="margin-top: 5px"
                v-model="operation_form.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="space-between">
          <el-col :span="12">
            <el-form-item label="针对关联">
              <el-select v-model="operation_form.operation_against">
                <el-option
                  v-for="(at_item, at_index) in against_arr"
                  :key="at_index"
                  :label="at_item"
                  :value="at_index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              v-if="operation_form.operation_against == 0"
              label="用户数据统计"
              prop="user_statistics_field"
            >
              <el-input
                v-model="operation_form.user_statistics_field"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="space-between">
          <el-col :span="12">
            <el-form-item label="评分方式">
              <el-select v-model="operation_form.operation_score_type">
                <el-option
                  v-for="(at_item, at_index) in operation_score_type_arr"
                  :key="at_index"
                  :label="at_item"
                  :value="at_index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="评分组件设置"
              v-if="operation_form.operation_score_type == 0"
            >
              <el-link
                type="success"
                :underline="false"
                style="font-weight: 900"
                @click="openSetBasicComponents"
                >点击设置评分组件</el-link
              >
            </el-form-item>
            <el-form-item
              v-if="operation_form.operation_score_type == 1"
              label="算分函数"
              prop="operation_function_name"
            >
              <el-input
                v-model="operation_form.operation_function_name"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveOperation">确 定</el-button>
      </div>
    </el-dialog>
    <!--评分组件的添加 end--->
    <div id="toolbar">
      <el-button size="medium" type="primary" @click="addOperation"
        >新增运营组件</el-button
      >
    </div>
    <div id="table" style="margin-top: 10px">
      <el-table :data="itemTableData" style="width: 100%; padding: 10px">
        <el-table-column prop="id" label="编号" width="60"> </el-table-column>
        <el-table-column prop="operation_name" label="组件名称">
        </el-table-column>
        <el-table-column prop="operation_sign" label="组件标识">
        </el-table-column>
        <el-table-column prop="school_text" label="学校"> </el-table-column>
        <el-table-column prop="operation_desc" label="描述" width="400">
        </el-table-column>
        <el-table-column prop="operation_proportion" label="占比" width="60">
        </el-table-column>
        <el-table-column prop="status" label="状态" width="60">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 1" type="success">正常</el-tag>
            <el-tag v-if="scope.row.status == 0" type="danger">关闭</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEditOperation(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelOperation(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px"
        background
        @current-change="changePagetoItem"
        :page-size="item_pagesize"
        :total="item_totalcount"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getOperationList,
  getSchoolList,
  saveOperation,
  getConfigData,
  delOperation,
  getBasicList,
} from "@/api/admin.js";
export default {
  data() {
    return {
      dialogTitle: "新增运营组件",
      dialogFormVisible: false,
      formLabelWidth: "120px",
      itemTableData: [],
      item_page: 1,
      item_pagesize: 10,
      item_totalcount: 0,
      hide_on_single_page: false,

      operation_form: {
        id: 0,
        operation_name: "",
        operation_sign: "",
        operation_school_id: "",
        operation_desc: "",
        operation_proportion: 0,
        operation_against: 0,
        user_statistics_field: "",
        operation_score_type: 0,
        operation_function_name: "",
        status: 1,
        operation_subcomponents: {},
      },
      cateTableData: [],
      optionProps: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
        expandTrigger: "click",
      },
      school_list: [],
      dialogBaseVisible: false,
      basic_component_list: [],
      current_chose_basic: [],
      current_chose_basic_name: "",
      answerData: [],
      selectedKeyValArr: [],
      against_arr: [],
      operation_score_type_arr: [],
    };
  },
  methods: {
    numberChange(val, maxNum) {
      //   let vm = this;
      let input_num = 0;
      //转换数字类型
      if (!Object.is(Number(val), NaN)) {
        input_num = Number(val);
      }
      this.operation_form.operation_proportion = input_num;
      //重新渲染
      this.$nextTick(() => {
        //比较输入的值和最大值，返回小的
        let num = Math.min(input_num, maxNum);
        //输入负值的情况下， = 0（可根据实际需求更该）
        if (num < 0) {
          this.operation_form.operation_proportion = 0;
        } else {
          //反之
          this.operation_form.operation_proportion = num;
        }
      });
    },
    handleDelOperation: function (id) {
      let vm = this;
      vm.$confirm("确认要删除该运营组件吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delOperation({
            delete_id: id,
          }).then((res) => {
            if (res.code == 1) {
              vm.$message({
                type: "success",
                message: "删除成功",
              });
              vm.getOpertionList();
            }
          });
        })
        .catch(() => {
          vm.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    delOperationByBasic: function (index, id) {
      let vm = this;
      vm.answerData.splice(index, 1);
      for (let i = 0; i < this.selectedKeyValArr.length; i++) {
        if (this.selectedKeyValArr[i] == id) {
          this.selectedKeyValArr.splice(i, 1);
        }
      }
    },
    // 编辑组件
    handleEditOperation: function (arr) {
      let vm = this;
      vm.dialogTitle = "编辑运营组件";
      vm.operation_form.id = arr["id"];
      vm.operation_form.operation_name = arr["operation_name"];
      vm.operation_form.operation_sign = arr["operation_sign"];
      if (arr["operation_school_id"] == 0) {
        vm.operation_form.operation_school_id = "";
      } else {
        vm.operation_form.operation_school_id = arr["operation_school_id"];
      }
      vm.operation_form.operation_desc = arr["operation_desc"];
      vm.operation_form.operation_proportion = arr["operation_proportion"];
      vm.operation_form.status = arr["status"];
      vm.operation_form.operation_against = arr["operation_against"];
      vm.operation_form.user_statistics_field = arr["user_statistics_field"];
      vm.operation_form.operation_score_type = arr["operation_score_type"];
      vm.operation_form.operation_function_name =
        arr["operation_function_name"];

      if (arr["operation_subcomponents"] != undefined) {
        vm.operation_form.operation_subcomponents =
          arr["operation_subcomponents"];
        vm.answerData = vm.operation_form.operation_subcomponents;
        if (vm.operation_form.operation_subcomponents.length > 0) {
          vm.operation_form.operation_subcomponents.forEach((val) => {
            vm.selectedKeyValArr.push(val.relation_id);
          });
        }
      }
      vm.dialogFormVisible = true;
    },
    // 组件分页切换
    changePagetoItem: function (page) {
      this.item_page = page;
      this.getOpertionList();
    },
    // 获取所有的组件
    getOpertionList: function () {
      let vm = this;
      getOperationList({
        page: vm.item_page,
        page_size: vm.item_pagesize,
        is_page: 1,
      }).then((res) => {
        if (res.code == 1) {
          vm.itemTableData = res.data.data;
          vm.item_totalcount = res.data.total;
        }
      });

      //   $.ajax({
      //     type: "get",
      //     url: "index.php?act=score_smartai&op=getopertionlist",
      //     data: {
      //       page: vm.item_page,
      //       pageSize: vm.item_pagesize,
      //       is_page: 1,
      //     },
      //     dataType: "json",
      //     success: function (res) {
      //       if (res.code == 200) {
      //         vm.itemTableData = res.data.data;
      //         vm.item_totalcount = res.data.total;
      //       }
      //     },
      //   });
    },
    //  保存组件
    saveOperation: function () {
      let vm = this;
      saveOperation({
        ...vm.operation_form,
      }).then((res) => {
        if (res.code == 1) {
          vm.dialogFormVisible = false;
          vm.getOpertionList();
        }
      });
      //   $.ajax({
      //     type: "post",
      //     url: "index.php?act=score_smartai&op=saveoperation",
      //     data: vm.operation_form,
      //     dataType: "json",
      //     success: function (res) {
      //       if (res.code == 200) {
      //         vm.dialogFormVisible = false;
      //         vm.getOpertionList();
      //       } else {
      //         vm.$message({
      //           type: "error",
      //           message: res.msg,
      //         });
      //       }
      //     },
      //   });
    },
    // 保存评分点
    saveOperationByBasic: function () {
      let vm = this;
      if (this.answerData.length <= 0) {
        vm.$message({
          type: "info",
          message: "请先添加评分组件",
        });
        return false;
      }
      this.operation_form.operation_subcomponents = this.answerData;
      this.dialogBaseVisible = false;
    },
    // 选择评分点类型
    changeChoseBasic: function (data) {
      let vm = this;
      vm.basic_component_list.forEach((val) => {
        if (val.id == data) {
          vm.current_chose_basic = {
            relation_id: val.id,
            clac_type: 0,
            basic_name: val.name,
          };
        }
      });
    },
    // 添加评分组件
    addBasicByBase: function () {
      if (Object.keys(this.current_chose_basic).length == 0) {
        this.$message({
          type: "info",
          message: "请先选择组件",
        });
        return false;
      }
      if (this.answerData.length <= 0) {
        this.answerData = [];
      }
      this.answerData.push(this.current_chose_basic);
      this.selectedKeyValArr.push(this.current_chose_basic.relation_id);
      this.current_chose_basic_name = "";
      this.current_chose_basic = {};
    },
    // 打开评分点设置
    openSetBasicComponents: function () {
      this.getBaseList();
      this.dialogBaseVisible = true;
    },
    // 打开添加组件
    addOperation: function () {
      this.operation_form = {
        id: 0,
        operation_name: "",
        operation_sign: "",
        operation_school_id: "",
        operation_desc: "",
        operation_proportion: 0,
        operation_against: 0,
        user_statistics_field: "",
        operation_score_type: 0,
        operation_function_name: "",
        status: 1,
        operation_subcomponents: {},
      };
      this.answerData = [];
      this.selectedKeyValArr = [];
      this.dialogTitle = "新增运营组件";
      this.dialogFormVisible = true;
    },
    // 获取所有学校
    getSchoolList: function () {
      getSchoolList().then((res) => {
        if (res.code == 1) {
          this.school_list = res.data;
        }
      });
    },
    // 获取所有的基础组件
    getBaseList: function () {
      let vm = this;
      getBasicList({
        is_page: 0,
        against_type: vm.operation_form.operation_against,
      }).then((res) => {
        if (res.code == 1) {
          vm.basic_component_list = res.data.data;
        }
      });
    },
    getConfig() {
      getConfigData().then((res) => {
        if (res.code == 1) {
       
          this.against_arr = res.data.against_info;
          this.operation_score_type_arr = res.data.operation_score_type_arr;
        }
      });
    },
  },

  created: function () {
    this.getConfig();
    this.getSchoolList();
    this.getOpertionList();
  },
};
</script>
<style lang="less" scoped>
.el-col {
  border-radius: 5px;
}
.grid-content {
  border-radius: 5px;
  min-height: 30px;
  text-align: center;
}
[v-cloak] {
  display: none !important;
}
.el-row {
  margin-bottom: 20px;
}
.bg-purple-left {
  text-align: left;
  margin-left: 15px;
  margin-top: 5px;
}
.bg-purple-right {
  text-align: right;
  margin-right: 15px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f1f1f1;
  border: 1px dashed #28a0bb;
  height: 50px;
}
.el-dialog__body {
  padding: 10px 10px;
}
</style>